import { Box, Grid, IconButton, InputAdornment, InputLabel, LinearProgress, makeStyles, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { ButtonTemplate } from '../../components/button/button-template';
import { TextFieldTemplate } from '../../components/input/textfield-template';
import { Participants } from '../../components/videos/participants';
import { UpdateVideoArea } from '../../components/videos/update-video-area';
import { EditStills } from '../../components/videos/edit-stills';
import { Autocomplete } from '@material-ui/lab';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { EncodeObject } from '../../lib/encode';
import { GetMarqueeTags, UpdateVideo } from '../../lib/graphql-command/videos-command';
import MessageDialog from '../../components/dialog/message-dialog';
import { Tags } from '../../components/videos/tags';
import { EditAuthor } from '../../components/videos/edit-author';
import AlarmIcon from "@material-ui/icons/AddAlarm";
import { InsertInvitation } from '@material-ui/icons';
import { navigate } from 'gatsby';
import axios from 'axios';

const useStyle = makeStyles(theme => ({
    // picker: {
        // '& .MuiPickersBasePicker-container': {
        // '.MuiGrid-grid-xs-6': {
        //     flexGrow: '0',
        //     maxWidth: '56%',
        //     flexBasis: '56%'
        // },
        // }
    // },
    input: {
        display: 'none'
    },
    thumbnail: {
        width: "150px",
        height: "auto",
        [theme.breakpoints.down("sm")]: {
          justifyContent: "center",
        },
    },
    objectCenterSM: {
        [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        },
    },

    textCenterXs: {
        [theme.breakpoints.down("xs")]: {
        textAlign: "center",
        },
    },

    DialogTitleStyle: {
        backgroundColor: theme.palette.primary.main,
    },

    DialogTitleTextStyle: {
        color: theme.palette.primary.contrastText,
        fontSize: "1em",
    },

    DialogContentStyle: {
        paddingTop: 0,
    },

    linkStyle: {
        textDecoration: "none",
        color: theme.palette.primary.main,
    },

    SuccessText: {
        color: theme.palette.success.main,
    },

    primaryColor: {
        color: theme.palette.primary.main,
    },

    VideoUploadStyle: {
        display: "none",
    },
    truncateText: {
        width: '250px',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    error: {
        fontStyle: 'italic',
        fontSize: '0.9rem',
        color: theme.palette.primary.main,
        '& li': {
        lineHeight: '1.5rem'
        }
    }
}))

const bucket = {
    video: process.env.GATSBY_BUCKET_VIDEO,
    image: process.env.GATSBY_BUCKET_IMAGE,
}

export const getSession = () => {
    let cookie = getCookie("mlnp_session");

    if (cookie) {
        return cookie;
    }

    console.log("returning local storage: " + localStorage.getItem("mlnp_session"));
    return localStorage.getItem("mlnp_session");
}

function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
}

const EditVideo = ({videoCode}) => {
    const [video, setVideo] = useState();
    const classes = useStyle();
    const [participants, setParticipants] = useState([])
    const [marqueeTagOptions, setMarqueeTagOptions] = useState();
    const [disabled, setDisabled] = useState(false);
    const [messageDialog, setMessageDialog] = useState();

    const [loadingProgress, setLoadingProgress] = useState({
        IDImageOne: null,
        IDImageTwo: null,
        VideoIntro: null,
        VideoReal: null,
        Stills: { 0: null, 1: null, 2: null, 3: null, 4: null },
    })

    const [uploadLink, setUploadLink] = useState({
        IDImageOne: "",
        IDImageTwo: "",
        VideoIntro: "",
        VideoReal: "",
        Still_0: "",
        Still_1: "",
        Still_2: "",
        Still_3: "",
        Still_4: "",
    })

    const [uploadFileName, setUploadFileName] = useState({
        IDImageOne: "",
        IDImageTwo: "",
        VideoIntro: "",
        VideoReal: "",
        Still_0: "",
        Still_1: "",
        Still_2: "",
        Still_3: "",
        Still_4: "",
    })

    const [helperText, setHelperText] = useState({
        FirstName: "",
        LastName: "",
        Birthday: "",
        Country: "",
        IDImageOne: "",
        IDImageTwo: "",
        VideoTitle: "",
        VideoDescription: "",
        VideoFilm: "",
        VideoIntro: "",
        VideoReal: "",
        Still_0: "",
        Still_1: "",
        Still_2: "",
        Still_3: "",
        Still_4: "",
        checkbox: "",
    })

    useEffect(() => {
        API.graphql(graphqlOperation(GetMarqueeTags))
        .then(result => setMarqueeTagOptions(result.data.getMarqueeTagList?.Items))
        .catch(err => console.log(err))

        const xhr = new XMLHttpRequest();
        xhr.open("GET", `https://makelovenotporn.tv/api/admin/videos/${videoCode}`);
        xhr.setRequestHeader('Authorization','Basic ' +  getSession());

        xhr.onload = (() => {
            const data = JSON.parse(xhr.responseText);
            if (data.error) {
                console.log(data.error);
            }

            for (let i = 0; i < 5; i++) {
                if (data.video && data.video.Stills && data.video.Stills[i]) {
                    data.video[`Still_${i}`] = data.video.Stills[i]
                }
            }
            if(data.video && !data.video.PublishedAt) data.video.PublishedAt = moment().format("yyyy-MM-DD HH:MM:ss")
            if (data.video) data.video.MarqueeTags = data.video.MarqueeTags.map(tag => tag && parseInt(tag.MarqueeTagID))
            setVideo(data.video);

            if (data.video) {
                let path = "/videos/" + data.video.RealUrl.split('.').slice(0, -1).join('.') + "/"
                document.cookie = `CloudFront-Key-Pair-Id=${data.RealCookie["CloudFront-Key-Pair-Id"]};secure;path=${path};domain=.makelovenotporn.tv`;
                document.cookie = `CloudFront-Signature=${data.RealCookie["CloudFront-Signature"]};secure;path=${path};domain=.makelovenotporn.tv`;
                document.cookie = `CloudFront-Policy=${data.RealCookie["CloudFront-Policy"]};secure;path=${path};domain=.makelovenotporn.tv`;
                path = "/videos/" + data.video.IntroUrl.split('.').slice(0, -1).join('.') + "/"
                document.cookie = `CloudFront-Key-Pair-Id=${data.IntroCookie["CloudFront-Key-Pair-Id"]};secure;path=${path};domain=.makelovenotporn.tv`;
                document.cookie = `CloudFront-Signature=${data.IntroCookie["CloudFront-Signature"]};secure;path=${path};domain=.makelovenotporn.tv`;
                document.cookie = `CloudFront-Policy=${data.IntroCookie["CloudFront-Policy"]};secure;path=${path};domain=.makelovenotporn.tv`;
            }
        });
        
        xhr.send();
    }, []);

    const handleInput = (item, data) => {
        setVideo(input => ({
          ...input,
          [item]: data,
        }))
        setHelperText(helperText => ({
          ...helperText,
          [item]: "",
        }))
    }

    const handleUpload = (item, file, maxSize, maxSizeText) => {
        if (file) {
          handleInput(item, "")
          setUploadFileName(uploadFileName => ({
            ...uploadFileName,
            [item]: "",
          }))
          setUploadLink(uploadLink => ({
            ...uploadLink,
            [item]: "",
          }))
          setLoadingProgress(loadingProgress => ({
            ...loadingProgress,
            [item]: null,
          }))

          if (file.size > maxSize) {
            setHelperText(helperText => ({
              ...helperText,
              [item]: `Your file is larger than maximum size ${maxSizeText}`,
            }))
            return false
          }

          Storage.configure({
            bucket: file.type.split("/")[0] === "video" ? bucket.video : bucket.image,
          })

          axios.get(`https://makelovenotporn.tv/api/uploads?type=${file.name.split(".").pop()}`, {
            headers: {
                Authorization: `Basic ${getSession()}`,
            }})
            .then(function (signed) {
                var config = {
                    onUploadProgress: function(e) {
                        setLoadingProgress(loadingProgress => ({
                            ...loadingProgress,
                            [item]: (100 * e.loaded) / e.total,
                        }))
                    }
                };
                console.log("signed: ", signed.data.result.url);
                axios.put(signed.data.result.signedRequest, file, config)
                .then(function (res) {
                    console.log("upload result: ", res);
                    setUploadLink(uploadLink => ({
                        ...uploadLink,
                        [item]: signed.data.result.url,
                    }))
                    handleInput(item, {
                        region: "us-west-2",
                        bucket:
                          file.type.split("/")[0] === "video"
                            ? bucket.video
                            : bucket.image,
                        key: new URL(signed.data.result.url).pathname.replace(/^\//, ""),
                    })
                    setUploadFileName(uploadFileName => ({
                        ...uploadFileName,
                        [item]: file.name,
                    }))
                })
                .catch(function (err) {
                    console.log(`error uploading file: ${err}`);
                });
            });
        }
    }

    const StatusOptions = [
        {
            key: 'pending',
            text: 'Pending',
            value: 0
        }, {
            key: 'published',
            text: 'Published',
            value: 1
        }, {
            key: 'draft',
            text: 'Draft',
            value: 2
        }
    ]

    const mandatoryFields = [
        { label: "Video's title", key: "VideoTitle" },
        { label: "Video's description", key: "VideoDescription" },
        { label: "Video's date", key: "VideoFilm" },
        { label: "Intro Video", key: "VideoIntro" },
        { label: "Your Video", key: "VideoReal" },
        { label: "Video's author", key: "UserID" },
    ]
    const checkHelperText = item => {
        let error = false
        if (!video[item.key]) {
          setHelperText(helperText => ({
            ...helperText,
            [item.key]: `${item.label} is required`,
          }))
          error = true
        }

        if (error) {
          return false
        }

        setHelperText(helperText => ({
          ...helperText,
          [item]: "",
        }))
        return true
    }

    const processStills = data => {
        const newData = data
        const stillData = []
        for (let i = 0; i < 5; i++) {
          if (newData[`Still_${i}`]) {
            stillData.push(newData[`Still_${i}`])
          }
        }
        newData.Stills = stillData
        return newData
    }

    const handleSubmit = () => {
        setDisabled(true)
        let isValid = true
        for (let i in mandatoryFields) {
            if (!checkHelperText(mandatoryFields[i])) {
                isValid = false
            }
        }
        if (isValid) {
            API.graphql(graphqlOperation(UpdateVideo, EncodeObject(processStills(video))))
            .then(() => {
                setDisabled(false)
                setMessageDialog('Update successfully')
            })
            .catch(err => {
                console.log(err)
            })
        } else {
            setDisabled(false)
        }
    }

    const handleSelectMarqueeTags = values => {
        setVideo(oldVideo => ({
            ...oldVideo, 'MarqueeTags': values.map(value => value.MarqueeTagID)
        }))
    }

    return (
        <Box p={10} className={classes.root}>
            {video && <Box>
                <Grid container>
                <Typography variant='h4'>Edit Video</Typography>
                    <ButtonTemplate
                        borderradius='50px'
                        variant='outlined'
                        color='primary'
                        size='small'
                        margin='0 0 5px 10px'
                        onClick={() => navigate('/new-video')}
                    >Add New</ButtonTemplate>
                </Grid>
                <Box py={5}>
                    <TextFieldTemplate
                        size='small'
                        label='Video title'
                        defaultValue={video?.VideoTitle}
                        helperText={helperText.VideoTitle}
                        onChange={e => {
                            handleInput('VideoTitle', e.target.value)
                        }}
                    />
                </Box>
                <Box py={5}>
                    <Autocomplete
						options={StatusOptions}
						size="small"
						getOptionLabel={option => option.text}
						clearOnBlur
                        closeIcon={false}
                        style={{width: 200}}
                        value={StatusOptions[video.Status]}
						renderInput={params => (
							<TextField
								{...params}
								variant="outlined"
                                label="Status"
                                helperText={helperText.Status}
								className={classes.textfieldStyle}
							/>
						)}
						onChange={(event, value) => value && handleInput('Status', value.value)}
					/>
                </Box>
                <Box>
                    <Typography color="primary" variant="h6">
                        Participants
                    </Typography>
                    <Box>
                        <Participants {...{participants, setParticipants, video, setVideo}}/>
                    </Box>
                </Box>
                <Box>
                    <Grid container>
                        <Grid item md={6}>
                            <UpdateVideoArea {...{video, handleUpload, uploadFileName, uploadLink, helperText, loadingProgress, handleInput}} />
                        </Grid>
                        <Grid item md={6}>
                            <EditStills {...{video, handleUpload, uploadFileName, uploadLink, loadingProgress, helperText}} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={10} justify='space-between'>
                        <Grid item md={6}>
                            <Box py={10}>
                                <Box>
                                    <TextFieldTemplate
                                        readonly
                                        size='small'
                                        label='Work'
                                        defaultValue={video?.VideoID}
                                    />
                                </Box>
                                <Box py={5}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            autoOk
                                            color='primary'
                                            variant="inline"
                                            inputVariant="outlined"
                                            format="MM/dd/yyyy"
                                            size="small"
                                            label='Film Date'
                                            value={video.VideoFilm}
                                            helperText={helperText.VideoFilm}
                                            error={helperText.VideoFilm ? true : false}
                                            maxDate={Date.now()}
                                            InputProps={{
                                                endAdornment: (
                                                  <InputAdornment position="end">
                                                    <IconButton>
                                                      <InsertInvitation />
                                                    </IconButton>
                                                  </InputAdornment>
                                                ),
                                            }}
                                            onChange={date => {
                                                if (date) {
                                                    if (date.toString() === "Invalid Date") {
                                                        setVideo(input => ({
                                                            ...input,
                                                            VideoFilm: null,
                                                        }))
                                                        setHelperText(helperText => ({
                                                        ...helperText,
                                                        VideoFilm:
                                                            "Please enter date in the requested format - MM/DD/YYYY",
                                                        }))
                                                    } else {
                                                        handleInput('VideoFilm', moment(date).format("YYYY-MM-DD"))
                                                    }
                                                }
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Box>
                                <Box py={5}>
                                    <TextFieldTemplate
                                        readonly
                                        size='small'
                                        label='Bucket'
                                        defaultValue={video?.VideoReal?.bucket}
                                    />
                                </Box>
                                <Box py={5}>
                                    <TextFieldTemplate
                                        readonly
                                        size='small'
                                        label='Full video key'
                                        defaultValue={video.VideoReal?.key}
                                    />
                                </Box>
                                <Box py={5}>
                                    <TextFieldTemplate
                                        readonly
                                        size='small'
                                        label='Trailer video key'
                                        defaultValue={video.VideoIntro?.key}
                                    />
                                </Box>

                                <Box py={5}>
                                    <TextFieldTemplate
                                        multiline
                                        rows={4}
                                        size='small'
                                        label='Description'
                                        helperText={helperText.VideoDescription}
                                        defaultValue={video.VideoDescription}
                                        onChange={e => handleInput('VideoDescription', e.target.value)}
                                        />
                                </Box>
                                <Box py={5}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DateTimePicker
                                            inputVariant="outlined"
                                            variant="inline"
                                            label="Publish on"
                                            size="small"
                                            format="MMM dd, yyyy HH:MM"
                                            ampm={false}
                                            value={video.PublishedAt}
                                            InputProps={{
                                                endAdornment: (
                                                  <InputAdornment position="end">
                                                    <IconButton>
                                                      <AlarmIcon />
                                                    </IconButton>
                                                  </InputAdornment>
                                                ),
                                            }}
                                            onChange={(datetime) => {
                                                handleInput('PublishedAt', moment(datetime).format("yyyy-MM-DD HH:MM:ss"))
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Box>

                                <EditAuthor {...{video, setVideo, handleInput}} />
                            </Box>
                        </Grid>
                        <Grid item md={4}>

                            <Tags {...{video, setVideo}} />
                            {marqueeTagOptions && <Box>
                                <Autocomplete
                                    multiple
                                    options={marqueeTagOptions}
                                    size="small"
                                    getOptionLabel={option => option.Name}
                                    disableCloseOnSelect
                                    defaultValue={marqueeTagOptions.filter(tag => video.MarqueeTags.includes(tag.MarqueeTagID))}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Marquee tags"
                                            className={classes.textfieldStyle}
                                        />
                                    )}
                                    onChange={(event, values) => handleSelectMarqueeTags(values)}
                                />
                            </Box>}
                        </Grid>
                    </Grid>


                </Box>
                <ButtonTemplate
                    disabled={disabled}
                    borderradius='50px'
                    variant='outlined'
                    color='primary'
                    onClick={handleSubmit}
                >
                    Save
                </ButtonTemplate>

            </Box>}
            <MessageDialog {...{message: messageDialog, setMessage: setMessageDialog}} />
        </Box>
    )
}

export default EditVideo;